




























































import Vue from 'vue';
import MainHeader from '@/components/MainHeader.vue'
import PageTitle from '@/components/PageTitle.vue'
import MainFooter from '@/components/MainFooter.vue'

export default Vue.extend({
  name: 'how',
  components: {
    MainHeader,
    PageTitle,
    MainFooter,
  },
});
